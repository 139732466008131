import usageExposerStatistics from "@shared/services/tour/mockedApis/usageExposer/usageExposerStatistics.ts";
import statisticsUtils from "@shared/utils/statistics-utils.ts";
import dateUtils from "@shared/utils/date-utils.ts";

export const getMonthToDateTraffic = async () => {
  const dashboardStats = await usageExposerStatistics({});
  return (
    statisticsUtils.totalDt(dashboardStats, dateUtils.firstOfCurrentMonth()) ||
    0
  );
};

export const getMonthToDatePeakStorage = async () => {
  const dashboardStats = await usageExposerStatistics({});
  return (
    statisticsUtils.totalStorage(
      dashboardStats,
      dateUtils.firstOfCurrentMonth(),
    ) || 0
  );
};
