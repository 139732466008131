import {
  USAGE_EXPOSER_AGGREGATED_USAGE_BREAKDOWN,
  USAGE_EXPOSER_GET_AGGREGATED_SCANS,
  USAGE_EXPOSER_GET_AGGREGATED_TRAFFIC_BY_PACKAGE_TYPE,
  USAGE_EXPOSER_GET_AGGREGATED_TRAFFIC_BY_PROJECT,
  USAGE_EXPOSER_GET_DAILY_CURATED_PACKAGES_VERDICT,
  USAGE_EXPOSER_GET_PACKAGES_TRAFFIC_FOR_PERIOD,
  USAGE_EXPOSER_GET_PROJECTS_TRAFFIC_FOR_PERIOD,
  USAGE_EXPOSER_GET_REPOSITORIES_TRAFFIC_FOR_PERIOD,
  USAGE_EXPOSER_GET_SERVER_DAILY_AGGREGATED_USAGE,
  USAGE_EXPOSER_SH_STATISTICS,
  USAGE_EXPOSER_STATISTICS,
} from "@shared/services/tour/urls/dashboard-urls.ts";
import usageExposerStatistics from "@shared/services/tour/mockedApis/usageExposer/usageExposerStatistics.ts";
import usageExposerGetDailyCuratedPackagesVerdict from "@shared/services/tour/mockedApis/usageExposer/usageExposerGetDailyCuratedPackagesVerdict.ts";
import usageExposerAggregatedUsageBreakdown from "@shared/services/tour/mockedApis/usageExposer/usageExposerAggregatedUsageBreakdown.ts";
import usageExposerGetAggregatedTrafficByProject from "@shared/services/tour/mockedApis/usageExposer/usageExposerGetAggregatedTrafficByProject.ts";
import usageExposerGetAggregatedTrafficByPackageType from "@shared/services/tour/mockedApis/usageExposer/usageExposerGetAggregatedTrafficByPackageType.ts";
import usageExposerGetRepositoriesTrafficForPeriod from "@shared/services/tour/mockedApis/usageExposer/usageExposerGetRepositoriesTrafficForPeriod.ts";
import usageExposerGetProjectsTrafficForPeriod from "@shared/services/tour/mockedApis/usageExposer/usageExposerGetProjectsTrafficForPeriod.ts";
import usageExposerGetPackagesTrafficForPeriod from "@shared/services/tour/mockedApis/usageExposer/usageExposerGetPackagesTrafficForPeriod.ts";
import usageExposerShStatistics from "@shared/services/tour/mockedApis/usageExposer/usageExposerShStatistics.ts";
import usageExposerGetServerDailyAggregatedUsage from "@shared/services/tour/mockedApis/usageExposer/usageExposerGetServerDailyAggregatedUsage.ts";
import usageExposerGetAggregatedScans from "@shared/services/tour/mockedApis/usageExposer/usageExposerGetAggregatedScans.ts";

export default {
  [USAGE_EXPOSER_STATISTICS]: (data?: object) => usageExposerStatistics(data),
  [USAGE_EXPOSER_GET_DAILY_CURATED_PACKAGES_VERDICT]: () =>
    usageExposerGetDailyCuratedPackagesVerdict(),
  [USAGE_EXPOSER_AGGREGATED_USAGE_BREAKDOWN]: (data?: object) =>
    usageExposerAggregatedUsageBreakdown(data),
  [USAGE_EXPOSER_GET_AGGREGATED_TRAFFIC_BY_PROJECT]: (data?: object) =>
    usageExposerGetAggregatedTrafficByProject(data),
  [USAGE_EXPOSER_GET_AGGREGATED_TRAFFIC_BY_PACKAGE_TYPE]: (data?: object) =>
    usageExposerGetAggregatedTrafficByPackageType(data),
  [USAGE_EXPOSER_GET_REPOSITORIES_TRAFFIC_FOR_PERIOD]: () =>
    usageExposerGetRepositoriesTrafficForPeriod(),
  [USAGE_EXPOSER_GET_PROJECTS_TRAFFIC_FOR_PERIOD]: () =>
    usageExposerGetProjectsTrafficForPeriod(),
  [USAGE_EXPOSER_GET_PACKAGES_TRAFFIC_FOR_PERIOD]: () =>
    usageExposerGetPackagesTrafficForPeriod(),
  [USAGE_EXPOSER_SH_STATISTICS]: () => usageExposerShStatistics(),
  [USAGE_EXPOSER_GET_SERVER_DAILY_AGGREGATED_USAGE]: () =>
    usageExposerGetServerDailyAggregatedUsage(),
  [USAGE_EXPOSER_GET_AGGREGATED_SCANS]: () => usageExposerGetAggregatedScans(),
};
