const stringUtils = {
  isNumber: (s: string): boolean => /^[+-]?\d+(\.\d+)?$/.test(s),
  formatUsageWithUnit: (s: string) => {
    const parts = s.split(" ");
    if (parts.length !== 2 || !stringUtils.isNumber(parts[0])) {
      return s;
    }
    const usage = +parts[0];
    const unit = parts[1];

    return `${usage.toLocaleString("en-US")} ${unit}`;
  },
};

export default stringUtils;
