import {
  FetchUsageRequest,
  FetchUsageResponse,
} from "@shared/services/usage-explorer/fetchUsage.ts";
import {
  replaceData,
  fillPartitionItems,
} from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-mock-tools.ts";
import { UnitFamily } from "@shared/utils/myJFrogUtils.ts";
import { NUMBER_OF_DAYS_IN_RANGE } from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-mock-const.ts";

const json = import("../../json/usageExplorer/usageExplorerGetUsageJas.json");

export default async (data?: object) => {
  const response = JSON.parse(JSON.stringify(await json)) as FetchUsageResponse;
  response.nonce = (data as FetchUsageRequest).nonce;
  fillPartitionItems(response, NUMBER_OF_DAYS_IN_RANGE);
  replaceData({
    response,
    dataPerDay: [
      2642, 3241, 3080, 2834, 344, 636, 2868, 3267, 3338, 2178, 457, 1244, 2931,
      2873, 3314, 3357, 2631, 376, 960, 2474, 2594, 3216, 3149, 2736, 488, 750,
      2892, 3212, 2803, 2516,
    ].reverse(),
    repartitionPercentage: [35, 15, 10, 9, 7, 5, 4, 2, 2, 11],
    unitFamily: UnitFamily.SIMPLE_NUMBER,
    computationMode: "overtime",
  });
  return response;
};
