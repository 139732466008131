import apiProxy from "@shared/utils/api-proxy";
import {
  type MigrationPluginRequest,
  type MigrationPluginResponse,
  type SubscriptionDTO,
  type ValidateSslRequest,
  type SubmitSslRequest,
  type GetSslListCertificatesResponse,
  type ValidateSslResponse,
  type UpdateExistingSslRequest,
  type DeleteCertificateRequest,
  type CreateOrUpdatePolicyRequest,
  type RoutingPolicyModel,
  type GetGeoLocationsResponse,
  type JpdsReadinessResponse,
  type JpdsReadinessRequest,
  type GetRoutingPolicyBySubscriptionIdResponse,
} from "@jfrog-ba/myjfrog-common";

export const jmisService = {
  installPlugin: async (request: MigrationPluginRequest) => {
    const response: MigrationPluginResponse | null = await apiProxy.post(
      `jmis/installPlugin`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  uninstallPlugin: async (request: MigrationPluginRequest) => {
    const response: MigrationPluginResponse | null = await apiProxy.post(
      `jmis/uninstallPlugin`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  getPluginStatus: async (request: MigrationPluginRequest) => {
    const response: MigrationPluginResponse | null = await apiProxy.post(
      `jmis/getPluginStatus`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
  getSslList: async (accountNumber: SubscriptionDTO["accountNumber"]) => {
    const response: GetSslListCertificatesResponse | null = await apiProxy.get(
      `jmis/ssl/${accountNumber}`,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  validateSslCertificate: async (
    request: ValidateSslRequest,
    accountNumber: SubscriptionDTO["accountNumber"],
  ) => {
    const response: ValidateSslResponse | null = await apiProxy.post(
      `jmis/ssl/validate/${accountNumber}`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  submitSslCertificate: async (
    request: SubmitSslRequest,
    accountNumber: SubscriptionDTO["accountNumber"],
  ) => {
    await apiProxy.post(`jmis/ssl/submit/${accountNumber}`, request);
  },

  editExistingCertificate: async (
    request: UpdateExistingSslRequest,
    accountNumber: SubscriptionDTO["accountNumber"],
  ) => {
    await apiProxy.post(`jmis/ssl/manage_domains/${accountNumber}`, request);
  },

  renewExistingCertificate: async (
    request: UpdateExistingSslRequest,
    accountNumber: SubscriptionDTO["accountNumber"],
  ) => {
    await apiProxy.post(`jmis/ssl/renew/${accountNumber}`, request);
  },

  deleteCertificate: async (
    request: DeleteCertificateRequest,
    accountNumber: SubscriptionDTO["accountNumber"],
  ) => {
    await apiProxy.post(`jmis/ssl/delete/${accountNumber}`, request);
  },
  createNewDnsRoutingPolicy: async (request: CreateOrUpdatePolicyRequest) => {
    const response: RoutingPolicyModel | null = await apiProxy.post(
      `jmis/routing_policy`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
  updateDnsRoutingPolicy: async (
    routingPolicyId: string,
    request: CreateOrUpdatePolicyRequest,
  ) => {
    const response: RoutingPolicyModel | null = await apiProxy.put(
      `jmis/routing_policy/${routingPolicyId}`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
  getGeoLocation: async () => {
    const response: GetGeoLocationsResponse | null =
      await apiProxy.get(`jmis/geo_locations`);
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
  getJpdsHealthStatus: async (request: JpdsReadinessRequest) => {
    const response: JpdsReadinessResponse | null = await apiProxy.post(
      `jmis/routing_policy/jpd_readiness`,
      request,
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },
  getRoutingPolicies: async (
    accountNumber: SubscriptionDTO["accountNumber"],
  ) => {
    const response: GetRoutingPolicyBySubscriptionIdResponse | null =
      await apiProxy.get(`jmis/routing_policy/${accountNumber}`);
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  enableRoutingPolicy: async (routingPolicyId: string) => {
    const response: RoutingPolicyModel | null = await apiProxy.post(
      `jmis/routing_policy/${routingPolicyId}/enable`,
      {},
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  disableRoutingPolicy: async (routingPolicyId: string) => {
    const response: RoutingPolicyModel | null = await apiProxy.post(
      `jmis/routing_policy/${routingPolicyId}/disable`,
      {},
    );
    if (!response) {
      throw new Error("Got no response from server");
    }
    return response;
  },

  deleteRoutingPolicy: async (routingPolicyId: string) => {
    await apiProxy.delete(`jmis/routing_policy/${routingPolicyId}`);
  },
};
