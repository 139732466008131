import {
  type EventLoggerCreateRequestV1,
  EventLoggerCreateRequestV2,
  type EventLoggerGetRequest,
  type EventLoggerGetResponse,
} from "@shared/types/services/activityLog.ts";
import apiProxy from "@shared/utils/api-proxy";
import logger from "@shared/utils/logger.ts";

export const eventLogger = {
  createNewEvent: async (request: EventLoggerCreateRequestV1) => {
    try {
      await apiProxy.post(`event-logger/event`, request);
    } catch (e) {
      logger.error(
        "ERROR logging event v1 with request:" + JSON.stringify(request),
        e,
      );
    }
  },

  getEvents: async (request: EventLoggerGetRequest) => {
    const response: EventLoggerGetResponse | null = await apiProxy.post(
      `event-logger/event/getEvents`,
      request,
    );
    if (!response) {
      logger.error("ERROR getting events from event-logger");
      throw new Error("ERROR got no response from server");
    }
    return response;
  },

  createNewEventV2: async (request: EventLoggerCreateRequestV2) => {
    try {
      await apiProxy.post(`event-logger/event/v2`, request);
    } catch (e) {
      logger.error(
        `ERROR logging event ${request.eventType} for subscriptionNumber: ${request.subscriptionNumber}`,
        e,
      );
    }
  },
};
