import {
  FetchUsageRequest,
  FetchUsageResponse,
} from "@shared/services/usage-explorer/fetchUsage.ts";
import {
  fillPartitionItems,
  replaceData,
} from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-mock-tools.ts";
import { UnitFamily } from "@shared/utils/myJFrogUtils.ts";
import { NUMBER_OF_DAYS_IN_RANGE } from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-mock-const.ts";
import { generateUpwardProgression } from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-traffic-mock-tools.ts";
import { getMonthToDatePeakStorage } from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-month-to-date-mock-tools.ts";

const json = import(
  "../../json/usageExplorer/usageExplorerGetUsageStorage.json"
);

export default async (data?: object) => {
  const response = JSON.parse(JSON.stringify(await json)) as FetchUsageResponse;
  response.nonce = (data as FetchUsageRequest).nonce;
  fillPartitionItems(response, NUMBER_OF_DAYS_IN_RANGE);
  const peakStorage = await getMonthToDatePeakStorage();
  replaceData({
    response,
    dataPerDay: generateUpwardProgression(
      NUMBER_OF_DAYS_IN_RANGE,
      peakStorage,
      peakStorage / 30,
    ).reverse(),

    repartitionPercentage: [57, 33, 5, 3, 2],
    computationMode: "peak",
    unitFamily: UnitFamily.DATA,
  });
  return response;
};
