export const generateMonthToDateValuesWithVariation = (
  numberOfDays: number,
  targetSum: number,
  variation = 5000000000,
) => {
  const baseValue = Math.floor(targetSum / numberOfDays);
  const result = Array(numberOfDays).fill(baseValue);

  for (let i = 0; i < numberOfDays; i++) {
    const factor1 = i % 7 === 0 || i % 6 === 0 ? -1 : 1;
    const factor2 = factor1 < 0 ? 0.3 : 2;
    const factor3 = i * 0.6;
    result[i] += variation * factor1 * factor2 * factor3;
  }

  const currentSum = result.reduce((a, b) => a + b, 0);
  const difference = targetSum - currentSum;

  if (difference !== 0) {
    const sign = Math.sign(difference);
    const absDifference = Math.abs(difference);
    const perElementAdjustment = Math.floor(absDifference / numberOfDays);
    const remainingAdjustment = absDifference % numberOfDays;

    for (let i = 0; i < numberOfDays; i++) {
      result[i] += perElementAdjustment * sign;
    }
    result[0] += remainingAdjustment;
  }

  return result;
};

export const generateUpwardProgression = (
  numberOfDays: number,
  max: number,
  variation = 4000000000,
) => {
  const result = [];

  let currentValue = max;

  for (let i = 0; i < numberOfDays; i++) {
    const factor1 = i % 7 === 0 || i % 6 === 0 ? -1 : 1;
    const factor2 = factor1 < 0 ? 8 : 3;
    currentValue += variation * factor1 * factor2;
    currentValue = Math.min(currentValue, max);
    result.push(currentValue);
  }

  return result.reverse();
};
