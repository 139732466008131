import { ActivityLogRow } from "@shared/types/services/activityLog.ts";
import { defineStore } from "pinia";

interface ActivityLogState {
  _events: ActivityLogRow[];
  _eventsAreLoading: boolean;
}

export const useActivityLogStore = defineStore("activityLog", {
  state: (): ActivityLogState => ({
    _events: [],
    _eventsAreLoading: true,
  }),
  getters: {
    events: (state) => {
      return state._events as ActivityLogRow[];
    },
    eventsAreLoading: (state) => {
      return state._eventsAreLoading;
    },
  },
  actions: {
    setEvents(events: ActivityLogRow[]) {
      this._events = events;
    },
    setEventsAreLoading(isLoading: boolean) {
      this._eventsAreLoading = isLoading;
    },
  },
});
