import axios, { type AxiosError } from "axios";
import { restClientCreator } from "@jfrog-ba/myjfrog-common";
import { appService } from "@shared/services/app";
import window from "@shared/utils/app-window";
import { router } from "@shared/module-transformers/router.ts";
import { sessionDataLocalStorage } from "@shared/module-transformers/localStorage.ts";
import { useEngine } from "@shared/use/useEngine.ts";
import { LocalStorageKeys } from "@shared/types/localTypes/storage.ts";
import { useNetworkStore } from "@shared/stores/network.ts";
import { useNotifications } from "@shared/module-transformers/useNotifications.ts";
import { useToastNotificationsStore } from "@shared/stores/toastNotifications.ts";
import { restTourCreator } from "@shared/services/tour/restTourMock.ts";

const axiosInstance = axios.create({
  baseURL: window.API_PROXY_URL,
  withCredentials: true,
});
let abortController = new AbortController();
axiosInstance.interceptors.request.use(async (config) => {
  if (useEngine().engine === "MOBILE") {
    const sessionData = await sessionDataLocalStorage.get(
      LocalStorageKeys.SESSION_DATA,
    );

    if (sessionData) {
      config.headers.set("X-Session-ID", sessionData.sessionId);
    }
  }

  const networkStore = useNetworkStore();
  if (!networkStore.connected) {
    useToastNotificationsStore().lockError();
    setTimeout(() => {
      useToastNotificationsStore().unlockError();
    }, 500);

    useNotifications().error("You are offline", {
      duration: 3000,
      forceDisplay: true,
    });
    if (abortController) {
      abortController.abort();
    }
    abortController = new AbortController();
    config.signal = abortController.signal;
    throw new axios.Cancel("offline");
  }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error: AxiosError) => {
    if (
      error?.response?.status === 401 &&
      error.config?.url !== "error-logger" &&
      !error.config?.url?.includes("resetPassword")
    ) {
      await appService.unauthorizedApiInterceptor(router);
      return;
    }
    return Promise.reject(error);
  },
);

const axiosRestClient = restClientCreator(axiosInstance);
const restClient =
  window.OPERATION_MODE === "tour"
    ? restTourCreator(axiosRestClient)
    : axiosRestClient;

export default restClient;
