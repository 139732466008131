import type { DateFilters } from "@shared/types/localTypes/export-csv.ts";
import { DateTime } from "luxon";
import window from "@shared/utils/app-window.ts";

const baseFormat = "DD MMM YY HH:mm";

const dateUtils = {
  format: (date: DateTime, format: string) => {
    //Transform the timestamp to a local date
    const dateLocal = date.toJSDate();
    dateLocal.setMinutes(
      dateLocal.getTimezoneOffset() + dateLocal.getMinutes(),
    );

    //Format it
    return DateTime.fromJSDate(dateLocal).toFormat(format || baseFormat);
  },
  getUtcValueEndOfDay: (date: number) =>
    dateUtils.getUtcValue(
      DateTime.fromMillis(date).plus({ days: 1 }).toMillis(),
    ),
  getUtcValue: (date: number) => {
    const dateObject = DateTime.fromMillis(date).toJSDate();

    return Date.UTC(
      dateObject.getFullYear(),
      dateObject.getMonth(),
      dateObject.getDate(),
    );
  },
  isDateInRangeOrEqualsByDay: (
    rangeStart: DateTime,
    rangeEnd: DateTime,
    dateToCheck: DateTime,
  ) => {
    const isDateInRange = dateToCheck >= rangeStart && dateToCheck <= rangeEnd;
    const isStartDateMatchesDateToCheck =
      rangeStart.hasSame(dateToCheck, "year") &&
      rangeStart.hasSame(dateToCheck, "month") &&
      rangeStart.hasSame(dateToCheck, "day");
    const isEndDateMatchesDateToCheck =
      rangeEnd.hasSame(dateToCheck, "year") &&
      rangeEnd.hasSame(dateToCheck, "month") &&
      rangeEnd.hasSame(dateToCheck, "day");
    return (
      isDateInRange ||
      isStartDateMatchesDateToCheck ||
      isEndDateMatchesDateToCheck
    );
  },
  formatUTC: (date: Date, format = baseFormat) =>
    DateTime.fromJSDate(date).toUTC().toFormat(format),

  dayDiff: (startDate: DateTime, endDate: DateTime) =>
    endDate.diff(startDate, ["days"]).toObject(),

  toSqlFormat: (date: Date): string => {
    return DateTime.fromJSDate(date).toFormat("yyyy-MM-dd");
  },

  dashboardGraphDateFormat: (
    timeStampStr: string,
    period: "daily" | "monthly",
  ): string | string[] => {
    const date = DateTime.fromMillis(parseInt(timeStampStr));
    const format = period === "monthly" ? "LLL" : "MMM d";

    if (date.month === DateTime.now().month) {
      return ["Current", "Month"];
    }

    return [dateUtils.format(date, format), dateUtils.format(date, "yyyy")];
  },

  graphDateFormat: (
    timeStampStr: string,
    period: "daily" | "monthly",
  ): string | string[] => {
    const date = DateTime.fromMillis(parseInt(timeStampStr));
    const format = period === "monthly" ? "MMMM" : "MMM d";

    return dateUtils.format(date, format);
  },

  disabledFromDate: (time: Date, dateFilters: DateFilters) => {
    if (window.OPERATION_MODE === "tour") {
      return true;
    }

    const now = new Date();
    const datePickerTime = time.getTime();

    if (dateFilters.dateTo === null) {
      return (
        datePickerTime < now.setMonth(now.getMonth() - 6) ||
        datePickerTime > Date.now()
      );
    }

    return (
      datePickerTime > dateFilters.dateTo.getTime() ||
      datePickerTime < now.setMonth(now.getMonth() - 6)
    );
  },

  disabledToDate: (time: Date, dateFilters: DateFilters) => {
    if (window.OPERATION_MODE === "tour") {
      return true;
    }

    const now = new Date();
    const datePickerTime = time.getTime();

    if (dateFilters.dateFrom === null) {
      return (
        datePickerTime < now.setMonth(now.getMonth() - 6) ||
        datePickerTime > Date.now()
      );
    }

    return (
      datePickerTime < dateFilters.dateFrom.getTime() ||
      datePickerTime > Date.now()
    );
  },

  substractDatesByDays: (date: string, days: number) => {
    const endDate = DateTime.fromMillis(parseInt(date));
    const startDate = endDate.minus({ days });

    return {
      dateFromByDays: startDate.toJSDate(),
      dateToByDays: endDate.toJSDate(),
    };
  },
  isTimestampExpired: (timestamp: number) =>
    DateTime.fromMillis(timestamp).startOf("day") <
    DateTime.now().startOf("day"),

  startOfMonth: (dateTime: DateTime) =>
    dateTime
      .startOf("month")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  endOfMonth: (dateTime: DateTime) =>
    dateTime
      .endOf("month")
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  now: (dateTime: DateTime) =>
    dateTime.set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
  firstOfCurrentMonth: () =>
    dateUtils.startOfMonth(DateTime.now()).toFormat("yyyy-LL-dd"),
  firstOfPreviousMonth: (month = 1) =>
    dateUtils
      .startOfMonth(DateTime.now().minus({ month }))
      .toFormat("yyyy-LL-dd"),
  returnDateFromToday: (day = 0) =>
    dateUtils.now(DateTime.now().minus({ day })).toFormat("yyyy-LL-dd"),
  daysInCurrentMonth: (): number => {
    return DateTime.now().daysInMonth ?? 0;
  },
  closestFullWeekDay: (): number => {
    const now = DateTime.now();
    if (now.day > 28) {
      return now.endOf("month").day;
    }
    if (!(now.day % 7)) {
      return now.day;
    }

    return 7 * (Math.floor(now.day / 7) + 1);
  },
  diffInDays: (date1Millis: number, date2Millis: number) => {
    return DateTime.fromMillis(date2Millis).diff(
      DateTime.fromMillis(date1Millis),
      "days",
    ).days;
  },
};

export default dateUtils;
