import { MenuItemNames, useNavStore } from "@shared/stores/navigation";
import { subscriptionsService } from "@shared/services/subscriptions";
import { permissions } from "@shared/services/permissions";
import { BomType } from "@jfrog-ba/myjfrog-common";

export const navigationService = {
  manageMenuItemsOnSubscriptionChanged: (subscriptionNumber: string) => {
    Object.values(MenuItemNames).forEach((menuItemName) => {
      useNavStore().setMenuItemVisibility(
        menuItemName,
        menuItemsCalculator[menuItemName].isVisible(subscriptionNumber),
      );
      useNavStore().setMenuItemDisabled(
        menuItemName,
        !menuItemsCalculator[menuItemName].isEnabled(subscriptionNumber),
      );
    });
  },
};

type MenuItemsCalculator = {
  [key in MenuItemNames]: {
    isVisible: (subscriptionNumber: string) => boolean;
    isEnabled: (subscriptionNumber: string) => boolean;
    notificationCount: (subscriptionNumber: string) => number;
  };
};

export const menuItemsCalculator: MenuItemsCalculator = {
  [MenuItemNames.Dashboard]: {
    isVisible: (subscriptionNumber) =>
      !!subscriptionsService.findCachedSubscription(subscriptionNumber),
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.UsageExplorer]: {
    isVisible: (subscriptionNumber) => {
      return !!subscriptionsService.findCachedSubscription(subscriptionNumber);
    },
    isEnabled: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        (!!subscriptionToCheck.meta.isSaas ||
          !!subscriptionToCheck.meta.hasJas ||
          !!subscriptionToCheck.meta.hasCuration)
      );
    },
    notificationCount: () => 0,
  },
  [MenuItemNames.Billing]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        !!subscriptionToCheck.meta.isSaas &&
        permissions.has("Billing.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.Topology]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        !!subscriptionToCheck.meta.isSaas &&
        !!subscriptionToCheck.meta.isMultiJpdsSupportedForSubscription &&
        permissions.has("Topology.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      if (!subscriptionToCheck || subscriptionToCheck.state === "ONHOLD") {
        return 0;
      }
      return (
        subscriptionToCheck.nbInstanceConfigurable +
        subscriptionToCheck.nbEdgesConfigurable
      );
    },
  },
  [MenuItemNames.Security]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        !!subscriptionToCheck.meta.isSaas &&
        permissions.has("Security.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.SupportTickets]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        !!subscriptionToCheck.meta.isSaas &&
        (!!subscriptionToCheck.meta.isEnterprisePlus ||
          !!subscriptionToCheck.meta.isEnterpriseX ||
          !!subscriptionToCheck.meta.isEnterpriseTeam) &&
        !subscriptionToCheck.meta.isTrial &&
        permissions.has("SupportTickets.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.Users]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        permissions.has("ManageUsers.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.NotificationCenter]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        !!subscriptionToCheck.meta.isSaas &&
        permissions.has("NotificationCenter.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.ActivityLog]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        permissions.has("ActivityLog.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.SupportContacts]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      const isSubscriptionPro =
        subscriptionToCheck?.meta.isCloudPro ||
        subscriptionToCheck?.meta.isCloudProX ||
        subscriptionToCheck?.meta.isProTeam ||
        subscriptionToCheck?.bom.type === BomType.PRO;

      const isProNotMPSubscription =
        isSubscriptionPro && !subscriptionToCheck?.meta.isMP;
      return (
        !!subscriptionToCheck &&
        !subscriptionToCheck.meta.isTrial &&
        !subscriptionToCheck.meta.isFree &&
        !subscriptionToCheck.meta.isEnterprisePlusHybrid &&
        !isProNotMPSubscription &&
        permissions.has("SupportContacts.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.Settings]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        !!subscriptionToCheck.meta.isSaas &&
        permissions.has("Settings.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.JPD]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        !!subscriptionToCheck.meta.isSelfHosted &&
        permissions.has("JPD.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.Licenses]: {
    isVisible: (subscriptionNumber) => {
      const subscriptionToCheck =
        subscriptionsService.findCachedSubscription(subscriptionNumber);
      return (
        !!subscriptionToCheck &&
        !!subscriptionToCheck.meta.isSelfHosted &&
        permissions.has("Licenses.ModuleAccess", subscriptionNumber)
      );
    },
    isEnabled: () => true,
    notificationCount: () => 0,
  },
  [MenuItemNames.Sandbox]: {
    isVisible: (subscriptionNumber) =>
      !!subscriptionNumber && import.meta.env.DEV,
    isEnabled: () => true,
    notificationCount: () => 0,
  },
};
