import { type RouteMeta } from "vue-router";

export interface MjfRouteMeta extends RouteMeta {
  requiresAuth?: boolean;
  isGuest?: boolean;
  allowDisplayInMobile?: boolean;
  autoLogout?: boolean;
  isLandingView?: boolean;
  parentRoute?: RouteNames;
}

export enum RouteNames {
  SplashTransition = "SplashTransition",
  Root = "Root",
  Auth = "Auth",

  Login = "Login",
  ForgotPassword = "ForgotPassword",
  ResetPassword = "ResetPassword",
  RegisterNow = "RegisterNow",
  Registration = "Registration",
  RegistrationPassword = "RegistrationPassword",
  GoogleAuthenticator = "GoogleAuthenticator",
  SSOLogin = "SSOLogin",
  SSOLoginFailed = "SSOLoginFailed",

  AllSubscriptions = "AllSubscriptions",
  Dashboard = "Dashboard",
  UsageExplorer = "UsageExplorer",
  Billing = "Billing",
  Topology = "Topology",
  Security = "Security",
  SecurityTopology = "SecurityTopology",
  SecurityJpd = "SecurityJpd",
  Users = "Users",
  SupportContacts = "Contacts",
  Settings = "Settings",
  NotificationCenter = "NotificationCenter",
  JPDs = "JPDs",
  Licenses = "Licenses",
  SupportTickets = "SupportTickets",
  QuickUpgrade = "QuickUpgrade",
  ActivityLog = "AuditLogs",
  Sandbox = "Sandbox",

  ErrorNotFound = "ErrorNotFound",
  Maintenance = "Maintenance",
  AppNeedUpdate = "AppNeedUpdate",
  ErrorNetwork = "ErrorNetwork",

  Redirect = "Redirect",
  RedirectReactivateServer = "RedirectReactivateServer",

  LoginBeforeUpgrade = "LoginBeforeUpgrade",

  LandingPlatformSSODisabled = "LandingPlatformSSODisabled",
}
