import { LocalStorageKeys } from "@shared/types/localTypes/storage";
import { useAuthStore } from "@shared/stores/auth";
import { usersService } from "@shared/services/user";
import { useSubscriptionsStore } from "@shared/stores/subscriptions";
import { cookiesService } from "@shared/services/cookies";
import { RouteNames } from "@shared/types/localTypes/router";
import { Router } from "vue-router";
import { genericLocalStorage } from "@shared/module-transformers/localStorage.ts";
import { notificationTokenService } from "@shared/services/notificationToken.ts";
import { usePushNotificationsPermissionStore } from "@shared/stores/pushNotificationsPermissionStore.ts";
import useInAppNotificationsLogic from "@shared/use/useInAppNotificationsLogic.ts";
import { useBootstrapStore } from "@shared/stores/bootstrap.ts";
import { useEngine } from "@shared/use/useEngine.ts";
import { DateTime } from "luxon";
import { useSupportTicketsStore } from "@shared/stores/supportTickets.ts";
import { heapService } from "@shared/services/heap.ts";
import { useSessionLogic } from "@shared/use/useSessionLogic.ts";
import logger from "@shared/utils/logger.ts";

export const appService = {
  homeUrl: () => {
    return window.location.origin;
  },
  setApplicationStartDate: () => {
    window.localStorage.setItem(
      LocalStorageKeys.APPLICATION_START_DATETIME,
      new Date().toISOString(),
    );
  },
  clearSessionCache: async () => {
    await genericLocalStorage.clear([
      LocalStorageKeys.FROM_QUERY,
      LocalStorageKeys.LAST_LOGIN_EMAIL,
    ]);
  },
  isFreshInstance: () => {
    const applicationStartDateStr = window.localStorage.getItem(
      LocalStorageKeys.APPLICATION_START_DATETIME,
    );
    if (applicationStartDateStr === null) {
      return true;
    }
    try {
      const now = new Date();
      const applicationStartDate = new Date(applicationStartDateStr);
      const diffInSeconds = Math.round(
        (now.getTime() - applicationStartDate.getTime()) / 1000,
      );
      return diffInSeconds <= 2;
    } catch (e) {
      logger.warn("Error while checking if fresh instance", e);
      return true;
    }
  },
  logOut: async () => {
    const sessionLogic = useSessionLogic();

    if (sessionLogic.canReload()) {
      await usersService.proxyLogout();
      await useAuthStore().clear();
      useSubscriptionsStore().clearSubscriptions();
      useSupportTicketsStore().clearSupportTickets();
      useBootstrapStore().setInitialDataLoaded(false);
      await appService.clearSessionCache();
      await appService.unregisterPushNotificationToken();
      await useInAppNotificationsLogic().stopInAppNotificationListening();
      heapService.clearAll();
    }

    if (useEngine().engine === "WEB") {
      window.localStorage.setItem(
        LocalStorageKeys.LAST_SUCCESSFUL_LOGOUT,
        DateTime.now().toMillis().toString(),
      );
      sessionLogic.lockReload();
      sessionLogic.unlockReloadWithDelay();
    }
  },
  unauthorizedApiInterceptor: async (router: Router) => {
    await usersService.handleUnauthorized();

    await cookiesService.setFromCookie();
    await router.push({ name: RouteNames.Login });
  },
  unregisterPushNotificationToken: async () => {
    const token = usePushNotificationsPermissionStore().token;
    if (!token) {
      return;
    }
    await notificationTokenService.unregisterNotificationToken({ token });
  },
};
