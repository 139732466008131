import { DateTime } from "luxon";
import { ActivityLogRow } from "@shared/types/services/activityLog.ts";

export default async () => {
  const jsonEvents = await import("../json/eventLoggerGetEvents.json")
    .then(JSON.stringify)
    .then(JSON.parse);

  return {
    ...jsonEvents,
    events: jsonEvents.events.map((event: ActivityLogRow, index: number) => ({
      ...event,
      eventCreationDate: DateTime.now()
        .minus({ months: 1 })
        .startOf("month")
        .plus({
          days: (index + 1) % 30,
          seconds: ((index + 1) * 1000) % 10000,
        })
        .toISO(),
    })),
  };
};
