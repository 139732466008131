import {
  type EventLoggerCreateRequestV1,
  EventLoggerCreateRequestV2,
  EventLoggerGetRequest,
} from "@shared/types/services/activityLog.ts";
import { eventLogger } from "@shared/services/eventLogger.ts";
import { useSubscriptionsStore } from "@shared/stores/subscriptions.ts";
import { useAuthStore } from "@shared/stores/auth.ts";

import { useNotifications } from "@shared/module-transformers/useNotifications.ts";
import { useActivityLogStore } from "@shared/stores/activityLog.ts";
import { messagesService } from "@shared/services/messages.ts";
import logger from "@shared/utils/logger.ts";

export const useEventLogger = () => {
  /**
   * @deprecated This function is deprecated and will be removed in future versions.
   */
  const logEvent = ({
    eventType,
    eventData,
    eventEntity,
  }: Pick<
    EventLoggerCreateRequestV1,
    "eventType" | "eventData" | "eventEntity"
  >) => {
    eventLogger.createNewEvent({
      eventType,
      eventData,
      eventEntity,
      subscriptionNumber: useSubscriptionsStore().getCurrentSubscriptionNumber,
      userEmail: useAuthStore().getUser?.email || "",
    });
  };

  const logEventV2 = ({
    eventData,
    eventType,
  }: Pick<EventLoggerCreateRequestV2, "eventData" | "eventType">) => {
    eventLogger.createNewEventV2({
      subscriptionNumber: useSubscriptionsStore().getCurrentSubscriptionNumber,
      eventData,
      eventType,
    });
  };

  const getEvents = async ({
    eventType,
    performedBy,
    performedVia,
    startDateInMillis,
    endDateInMillis,
    subscriptionNumber,
  }: EventLoggerGetRequest) => {
    const activityLogStore = useActivityLogStore();

    try {
      activityLogStore.setEventsAreLoading(true);
      const eventLoggerGetResponse = await eventLogger.getEvents({
        subscriptionNumber,
        eventType,
        performedBy,
        performedVia,
        startDateInMillis,
        endDateInMillis,
      });

      activityLogStore.setEvents(eventLoggerGetResponse?.events || []);
    } catch (e) {
      logger.error(e);
      await useNotifications().error(
        messagesService.event_logger_get_events_error,
      );
    } finally {
      activityLogStore.setEventsAreLoading(false);
    }
  };

  return {
    logEvent,
    logEventV2,
    getEvents,
  };
};
