export enum EventEntity {
  USER = "USER",
  PLAN = "PLAN",
  LOG_STREAMER = "LOG_STREAMER",
}

export enum EventType {
  DELETE = "DELETE",
  NOTIFICATION_CREATED = "NOTIFICATION_CREATED",
  NOTIFICATION_UPDATED = "NOTIFICATION_UPDATED",
  NOTIFICATION_DELETED = "NOTIFICATION_DELETED",
  UPDATE = "UPDATE",
  QUICK_GET_QUOTE = "QUICK_GET_QUOTE",
  GET_QUOTE = "GET_QUOTE",
  REQUEST_TRIAL_JAS = "REQUEST_TRIAL_JAS",
  REQUEST_TRIAL_CURATION = "REQUEST_TRIAL_CURATION",
  ENABLE = "ENABLE",
  EXPORT_USAGE_CSV = "EXPORT_USAGE_CSV",
  DISABLE = "DISABLE",

  UPGRADE = "UPGRADE",
  IP_ALLOW_LIST_EXPORT_CSV = "IP_ALLOW_LIST_EXPORT_CSV",
  BILLING_REPORT_DOWNLOAD = "BILLING_REPORT_DOWNLOAD",
  BILLING_CREDIT_CARD_EDIT = "BILLING_CREDIT_CARD_EDIT",
  LICENSES_EXPORT_KEY = "LICENSES_EXPORT_KEY",
  LICENSES_EXPORT_BUCKET = "LICENSES_EXPORT_BUCKET",
  LICENSES_DOWNLOAD_SIGNED_URL = "LICENSES_DOWNLOAD_SIGNED_URL",
  LICENSES_COPY_SIGNED_URL = "LICENSES_COPY_SIGNED_URL",
  LICENSES_COPY_BUCKET_KEY = "LICENSES_COPY_BUCKET_KEY",
  AUDIT_LOGS_EXPORT_CSV = "AUDIT_LOGS_EXPORT_CSV",
}
