import { entitlementsService } from "@shared/services/entitlements";
import type { BusinessEntitlementConfig } from "@shared/types/localTypes/entitlements";
import { EntitlementName } from "@shared/types/localTypes/entitlements";
import { requestJasTrial } from "@shared/utils/jasTrialRequestUtil";
import { requestCurationTrial } from "@shared/utils/curationTrialRequestUtil";
import stringUtils from "@shared/utils/string-utils.ts";

export const entitlementsConfig: BusinessEntitlementConfig[] = [
  {
    entitlementName: EntitlementName.VIRTUAL_PURCHASED_DT,
    displayName: "Data Transfer",
    isCustomerFacing: true,
    cardConfig: {
      isTrial: () => false,
      icon: "data-transfer-icon",
      title: "Data Transfer",
      valueTransformer: (value) => {
        if (value === "N/A" || value === "Annual Contract") {
          return value;
        }
        return stringUtils.formatUsageWithUnit(value) + "/month";
      },
      valueTransformerSH: (value) => {
        return {
          display: value !== "N/A",
          value: stringUtils.formatUsageWithUnit(value),
          units: "Per month",
        };
      },
    },
  },
  {
    entitlementName: EntitlementName.VIRTUAL_PURCHASED_STORAGE,
    displayName: "Data Storage",
    isCustomerFacing: true,
    cardConfig: {
      isTrial: () => false,
      icon: "data-storage-icon",
      title: "Data Storage",
      valueTransformer: (value) => {
        if (value === "N/A" || value === "Annual Contract") {
          return value;
        }
        return stringUtils.formatUsageWithUnit(value) + "/month";
      },
      valueTransformerSH: (value) => {
        return {
          display: value !== "N/A",
          value: stringUtils.formatUsageWithUnit(value),
          units: "Per month",
        };
      },
    },
  },
  {
    entitlementName: EntitlementName.VIRTUAL_PURCHASED_USAGE,
    displayName: "Consumption",
    isCustomerFacing: true,
    cardConfig: {
      isTrial: () => false,
      icon: "trend-up",
      title: "Consumption",
      valueTransformer: (value) => {
        if (value === "N/A" || value === "Annual Contract") {
          return value;
        }
        return stringUtils.formatUsageWithUnit(value) + "/month";
      },
      valueTransformerSH: (value) => {
        return {
          display: value !== "N/A",
          value: stringUtils.formatUsageWithUnit(value),
          units: "Per month",
        };
      },
    },
  },
  {
    entitlementName: EntitlementName.JFRT,
    displayName: "Artifactory",
    isCustomerFacing: true,
    cardConfig: {
      isTrial: () => false,
      icon: "jpd-icon",
      title: "Platform",
      valueTransformer: (value) => value,
      valueTransformerSH: (value) => {
        return { display: value !== "N/A", value: value };
      },
    },
  },
  {
    entitlementName: EntitlementName.JFXR,
    displayName: "Xray",
    isCustomerFacing: true,
  },
  {
    entitlementName: EntitlementName.EDGE,
    displayName: "Edge",
    isCustomerFacing: true,
    cardConfig: {
      isTrial: () => false,
      icon: "jpd-icon",
      title: "Distribution Edge",
      valueTransformer: (value) => value,
      valueTransformerSH: (value) => {
        return { display: value !== "N/A", value: value };
      },
    },
  },
  {
    entitlementName: EntitlementName.VIRTUAL_INSTANCES_QTY,
    displayName: "Xray",
    isCustomerFacing: true,
    cardConfig: {
      isTrial: () => false,
      icon: "jpd-icon",
      title: "Additional Platform",
      valueTransformer: (value) => value,
      valueTransformerSH: (value) => {
        return { display: value !== "N/A", value: value };
      },
      tooltip: {
        text: "JFrog Platform with <br> Artifactory and Xray",
      },
    },
  },
  {
    entitlementName: EntitlementName.VIRTUAL_MOTHERSHIP_QTY,
    displayName: "Xray",
    isCustomerFacing: true,
    cardConfig: {
      isTrial: () => false,
      icon: "jpd-icon",
      title: "E+ Platform",
      valueTransformer: (value) => value,
      valueTransformerSH: (value) => {
        return { display: value !== "N/A", value: value };
      },
      tooltip: {
        text: "JFrog Platform that includes <br> all the platform capabilities.",
        link: "https://jfrog.com/help/r/get-started-with-the-jfrog-platform/jfrog-platform-overview",
      },
    },
  },
  {
    entitlementName: EntitlementName.PIPELINES,
    displayName: "Concurrent Pipelines Builds",
    displayOrder: 2,
    isCustomerFacing: true,
    cardConfig: {
      isTrial: () => false,
      icon: "build-minutes-icon",
      title: "Concurrent Pipelines Builds",
      valueTransformer: (value) => Number(value).toLocaleString("en-US"),
      valueTransformerSH: (value) => {
        return {
          display: value !== "N/A",
          value: Number(value).toLocaleString("en-US"),
          units: "Min",
        };
      },
      displayNA: true,
    },
  },
  {
    entitlementName: EntitlementName.PROJECTS,
    displayName: "Projects",
    displayOrder: 3,
    isCustomerFacing: true,
    cardConfig: {
      isTrial: () => false,
      icon: "projects-icon",
      title: "Projects",
      valueTransformer: (value) => Number(value).toLocaleString("en-US"),
      valueTransformerSH: (value) => {
        return {
          display: value !== "N/A",
          value: Number(value).toLocaleString("en-US"),
          units: "Per month",
        };
      },
      displayNA: true,
    },
  },
  {
    entitlementName: EntitlementName.PDN,
    displayName: "Pdn",
    isCustomerFacing: true,
  },
  {
    entitlementName: EntitlementName.SCANS,
    displayName: "Advanced Security Scans",
    displayOrder: 4,
    isCustomerFacing: false,
  },
  {
    entitlementName: EntitlementName.BUSINESS_SECURITY_ADVANCED_USERS,
    displayName: "Advanced Security",
    isCustomerFacing: true,
    displayOrder: 5,
    cardConfig: {
      isTrial: (subscription) =>
        !!subscription.meta.hasJasTrial && !subscription.meta?.isTrial,
      expirationDate: (subscription) =>
        entitlementsService.getEntitlement(
          subscription,
          EntitlementName.BUSINESS_SECURITY_ADVANCED_USERS,
        )?.endDate,
      icon: "jas-icon",
      title: "Advanced Security",
      displayEmptyState: true,
      valueTransformer: (value, hasJasTrial) =>
        `${hasJasTrial ? "Unlimited" : value} Users`,
      valueTransformerSH: (value) => {
        return {
          display: value !== "N/A",
          value: Number(value).toLocaleString("en-US"),
          units: "Users",
        };
      },
      setMeUp: async () => {
        await requestJasTrial();
      },
    },
  },
  {
    entitlementName: EntitlementName.BUSINESS_SECURITY_CURATION_USERS,
    displayName: "Curation",
    isCustomerFacing: true,
    displayOrder: 6,
    cardConfig: {
      isTrial: (subscription) =>
        !!subscription.meta.hasCurationTrial && !subscription.meta?.isTrial,
      expirationDate: (subscription) =>
        entitlementsService.getEntitlement(
          subscription,
          EntitlementName.BUSINESS_SECURITY_CURATION_USERS,
        )?.endDate,
      icon: "curation-icon",
      title: "Curation",
      displayEmptyState: true,
      valueTransformer: (value, hasCurationTrial) =>
        `${hasCurationTrial ? "Unlimited" : value} Users`,
      valueTransformerSH: (value) => {
        return {
          display: value !== "N/A",
          value: Number(value).toLocaleString("en-US"),
          units: "Users",
        };
      },
      setMeUp: async () => {
        await requestCurationTrial();
      },
    },
  },
  {
    entitlementName: EntitlementName.COLD_STORAGE,
    displayName: "Cold Storage",
    isCustomerFacing: false,
  },
  {
    entitlementName: EntitlementName.COLD_STORAGE_UPLOAD,
    displayName: "Cold Storage Upload",
    isCustomerFacing: false,
  },
  {
    entitlementName: EntitlementName.BUILD_MINUTES,
    displayName: "Build Minutes",
    isCustomerFacing: false,
    cardConfig: {
      isTrial: () => false,
      icon: "build-minutes-icon",
      title: "Build Minutes",
      valueTransformer: (value) => {
        const formattedValue = Number(value).toLocaleString("en-US");
        return formattedValue + " Min/month ";
      },
      valueTransformerSH: (value) => {
        return {
          display: value !== "N/A",
          value: Number(value).toLocaleString("en-US"),
          units: "Min",
        };
      },
      displayNA: true,
    },
  },
  {
    entitlementName: EntitlementName.BUSINESS_SECURITY_ADVANCED,
    displayName: "Business Security",
    isCustomerFacing: false,
  },
  {
    entitlementName: EntitlementName.CUSTOMER_UX_CODE,
    displayName: "Customer Ux Code",
    isCustomerFacing: false,
  },
];
