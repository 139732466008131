import {
  USAGE_EXPLORER_GET_USAGE_CURATION,
  USAGE_EXPLORER_GET_USAGE_JAS,
  USAGE_EXPLORER_GET_USAGE_PROJECTS,
  USAGE_EXPLORER_GET_USAGE_STORAGE,
  USAGE_EXPLORER_GET_USAGE_TRAFFIC,
} from "@shared/services/tour/urls/dashboard-urls.ts";
import usageExplorerGetUsageStorage from "@shared/services/tour/mockedApis/usageExplorer/usageExplorerGetUsageStorage.ts";
import usageExplorerGetUsageTraffic from "@shared/services/tour/mockedApis/usageExplorer/usageExplorerGetUsageTraffic.ts";
import usageExplorerGetUsageProjects from "@shared/services/tour/mockedApis/usageExplorer/usageExplorerGetUsageProjects.ts";
import usageExplorerGetUsageJas from "@shared/services/tour/mockedApis/usageExplorer/usageExplorerGetUsageJas.ts";
import usageExplorerGetUsageCuration from "@shared/services/tour/mockedApis/usageExplorer/usageExplorerGetUsageCuration.ts";

export default {
  [USAGE_EXPLORER_GET_USAGE_TRAFFIC]: (data?: object) =>
    usageExplorerGetUsageTraffic(data),
  [USAGE_EXPLORER_GET_USAGE_STORAGE]: (data?: object) =>
    usageExplorerGetUsageStorage(data),
  [USAGE_EXPLORER_GET_USAGE_PROJECTS]: (data?: object) =>
    usageExplorerGetUsageProjects(data),
  [USAGE_EXPLORER_GET_USAGE_JAS]: (data?: object) =>
    usageExplorerGetUsageJas(data),
  [USAGE_EXPLORER_GET_USAGE_CURATION]: (data?: object) =>
    usageExplorerGetUsageCuration(data),
};
