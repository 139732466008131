import {
  FetchUsageRequest,
  FetchUsageResponse,
} from "@shared/services/usage-explorer/fetchUsage.ts";
import {
  fillPartitionItems,
  replaceData,
} from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-mock-tools.ts";
import { UnitFamily } from "@shared/utils/myJFrogUtils.ts";
import { DateTime } from "luxon";
import {
  generateMonthToDateValuesWithVariation,
  generateUpwardProgression,
} from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-traffic-mock-tools.ts";
import { NUMBER_OF_DAYS_IN_RANGE } from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-mock-const.ts";
import { getMonthToDateTraffic } from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-month-to-date-mock-tools.ts";

const json = import(
  "../../json/usageExplorer/usageExplorerGetUsageTraffic.json"
);

export default async (data?: object) => {
  const response = JSON.parse(JSON.stringify(await json)) as FetchUsageResponse;
  response.nonce = (data as FetchUsageRequest).nonce;

  const startOfMonth = DateTime.now().startOf("month");
  const now = DateTime.now();
  const numberOfDaysPastThisMonth = Math.ceil(
    now.diff(startOfMonth, "days").days,
  );
  const remainingDaysInRange = 30 - numberOfDaysPastThisMonth;

  fillPartitionItems(response, NUMBER_OF_DAYS_IN_RANGE);

  const monthToDateTotalTraffic = await getMonthToDateTraffic();

  const monthToDateValuesWithVariation = generateMonthToDateValuesWithVariation(
    numberOfDaysPastThisMonth,
    monthToDateTotalTraffic,
  );
  const upwardProgression = generateUpwardProgression(
    remainingDaysInRange,
    Math.max(...monthToDateValuesWithVariation),
  );

  replaceData({
    response,
    dataPerDay: [
      ...upwardProgression,
      ...monthToDateValuesWithVariation,
    ].reverse(),
    repartitionPercentage: [35, 20, 5, 5, 5, 4, 4, 2, 1, 19],
    computationMode: "overtime",
    unitFamily: UnitFamily.DATA,
  });
  return response;
};
