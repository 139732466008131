import { DateTime } from "luxon";
import { UsageExposerStatisticsV2Response } from "@shared/services/usageExposer/usageExposerStatistics.ts";

const statisticsUtils = {
  totalStorage: (
    _data: UsageExposerStatisticsV2Response | null,
    month: string,
  ) =>
    _data?.serverStatistics.reduce((accumulator, server) => {
      const serverTraffic =
        server.statistics.find((s) => s.date === month)?.totalStorage || 0;
      return accumulator + serverTraffic;
    }, 0),
  totalDt: (_data: UsageExposerStatisticsV2Response | null, month: string) =>
    _data?.serverStatistics.reduce((accumulator, server) => {
      const serverTraffic =
        server.statistics.find((s) => s.date === month)?.totalTrafficBytes || 0;
      return accumulator + serverTraffic;
    }, 0),
  computeStatisticsDates: (
    periodStart: DateTime,
    periodEnd: DateTime,
    duration: "day" | "month" = "day",
  ) => {
    const statisticsDates = [];
    let currentDate = periodStart;
    while (currentDate <= periodEnd) {
      statisticsDates.push(currentDate.toFormat("yyyy-LL-dd"));
      currentDate = currentDate.plus({ [duration]: 1 });
    }
    return statisticsDates;
  },
};

export default statisticsUtils;
