import { FetchUsageResponse } from "@shared/services/usage-explorer/fetchUsage.ts";
import { UsageGroupByType } from "@shared/types/localTypes/usage-explorer.ts";
import dateUtils from "@shared/utils/date-utils.ts";
import { deepClone } from "@shared/utils/object-tools.ts";
import { UnitFamily } from "@shared/utils/myJFrogUtils.ts";

type UsageExplorerDataReplacementConfig = {
  response: FetchUsageResponse;
  dataPerDay: number[];
  repartitionPercentage: number[];
  computationMode?: "overtime" | "peak";
  unitFamily: UnitFamily;
};

export const fillPartitionItems = (
  response: FetchUsageResponse,
  numberOfDays: number,
) => {
  for (const key in response.partition_group_by_result) {
    const partitionResult =
      response.partition_group_by_result[key as UsageGroupByType];

    if (!partitionResult) {
      continue;
    }

    const reference = partitionResult[0];
    response.entire_period_group_by_result[key as UsageGroupByType] =
      deepClone(reference);

    reference.date = dateUtils.returnDateFromToday(0);

    for (let i = 1; i < numberOfDays; i++) {
      const newItem = JSON.parse(JSON.stringify(reference));
      newItem.date = dateUtils.returnDateFromToday(i);
      partitionResult.push(newItem);
    }
  }
};

export const replaceData = (config: UsageExplorerDataReplacementConfig) => {
  replaceDataInEntireResult(config);
  replaceDataInPartitionResults(config);
};

const replaceDataInEntireResult = ({
  response,
  repartitionPercentage,
  computationMode,
  unitFamily,
  dataPerDay,
}: UsageExplorerDataReplacementConfig) => {
  const total = dataPerDay.reduce((acc, curr) => acc + curr, 0);
  const peak = dataPerDay.reduce((acc, curr) => Math.max(acc, curr), 0);

  for (const key in response.entire_period_group_by_result) {
    const entireResult =
      response.entire_period_group_by_result[key as UsageGroupByType];

    if (!entireResult) {
      continue;
    }

    const hasOther = !!entireResult.remaining;
    const itemsQty = entireResult.top.length;
    const baseValue = computationMode === "overtime" ? total : peak;

    for (let i = 0; i < itemsQty; i++) {
      let percentage = repartitionPercentage[i];
      const isLastItem = i === itemsQty - 1;
      if (!hasOther && isLastItem && itemsQty < repartitionPercentage.length) {
        percentage = repartitionPercentage
          .slice(i)
          .reduce((acc, curr) => acc + curr, 0);
      }
      const value = baseValue * (percentage / 100);
      entireResult.top[i][1] =
        unitFamily === UnitFamily.SIMPLE_NUMBER ? Math.floor(value) : value;
    }

    if (hasOther) {
      const otherRepartitionPercentage = repartitionPercentage
        .slice(itemsQty)
        .reduce((acc, curr) => acc + curr, 0);

      const otherValue = baseValue * (otherRepartitionPercentage / 100);
      entireResult.remaining = [
        1,
        unitFamily === UnitFamily.SIMPLE_NUMBER
          ? Math.floor(otherValue)
          : otherValue,
      ];
    }
  }
};

const replaceDataInPartitionResults = ({
  response,
  repartitionPercentage,
  unitFamily,
  dataPerDay,
}: UsageExplorerDataReplacementConfig) => {
  for (const key in response.partition_group_by_result) {
    const partitionResult =
      response.partition_group_by_result[key as UsageGroupByType];

    if (!partitionResult) {
      continue;
    }

    partitionResult.forEach((partitionItem, dayIndex) => {
      const itemsQtyForDay = partitionItem.top.length;
      const dayHasOther = !!partitionItem.remaining;

      for (let i = 0; i < itemsQtyForDay; i++) {
        let percentage = repartitionPercentage[i];
        const isLastItemOfDay = i === itemsQtyForDay - 1;
        if (
          !dayHasOther &&
          isLastItemOfDay &&
          itemsQtyForDay < repartitionPercentage.length
        ) {
          percentage = repartitionPercentage
            .slice(i)
            .reduce((acc, curr) => acc + curr, 0);
        }
        const value = dataPerDay[dayIndex] * (percentage / 100);
        partitionItem.top[i][1] =
          unitFamily === UnitFamily.SIMPLE_NUMBER ? Math.floor(value) : value;
      }

      if (dayHasOther) {
        const otherRepartitionPercentage = repartitionPercentage
          .slice(itemsQtyForDay)
          .reduce((acc, curr) => acc + curr, 0);

        const otherValue =
          dataPerDay[dayIndex] * (otherRepartitionPercentage / 100);
        partitionItem.remaining = [
          1,
          unitFamily === UnitFamily.SIMPLE_NUMBER
            ? Math.floor(otherValue)
            : otherValue,
        ];
      }
    });
  }
};
