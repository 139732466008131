import {
  SubscriptionDTO,
  CreateOrUpdatePolicyRequest,
} from "@jfrog-ba/myjfrog-common";
import { jmisService } from "@shared/services/jmis.ts";
import { useNotifications } from "@shared/module-transformers/useNotifications.ts";
import { messagesService } from "@shared/services/messages.ts";
import { useDnsRoutingStore } from "@shared/stores/dnsRouting.ts";
import useSettingsHelpers from "@shared/use/useSettingsHelpers.ts";
import { permissions } from "@shared/services/permissions.ts";
import logger from "@shared/utils/logger.ts";

export const useDnsRoutingHelper = () => {
  const dnsRoutingStore = useDnsRoutingStore();
  const fetchRoutingPolicies = async (currentSubscription: SubscriptionDTO) => {
    const {
      isEntitledDnsRoutingManualFailoverPolicy,
      isEntitledDnsRoutingGeoLocationPolicy,
    } = useSettingsHelpers();
    const isEntitledDns =
      isEntitledDnsRoutingGeoLocationPolicy.value ||
      isEntitledDnsRoutingManualFailoverPolicy.value;
    const hasDnsAccessPermissions = permissions.has(
      "Settings.Tabs.DnsRouting.ModuleAccess",
    );
    if (!isEntitledDns || !hasDnsAccessPermissions) {
      return;
    }
    try {
      const response = await jmisService.getRoutingPolicies(
        currentSubscription.accountNumber,
      );
      dnsRoutingStore.setRoutingPoliciesResponse(response);
    } catch (e) {
      logger.error(messagesService.dns_routing_fetch_policies_failed, e);
      useNotifications().error(
        messagesService.dns_routing_fetch_policies_failed,
      );
    }
  };

  const fetchJpdHealthStatus = async (currentSubscription: SubscriptionDTO) => {
    try {
      const technicalServerNames = currentSubscription.jpus.map(
        (jpd) => jpd.technicalServerName,
      );
      const readinessResponse = await jmisService.getJpdsHealthStatus({
        technical_server_names: technicalServerNames,
      });
      dnsRoutingStore.setJpdsReadinessResponse(readinessResponse);
    } catch (e) {
      logger.error(messagesService.dns_routing_fetch_jpd_readiness_failed, e);
      useNotifications().error(
        messagesService.dns_routing_fetch_jpd_readiness_failed,
      );
    }
  };

  const fetchGeoLocationsList = async () => {
    try {
      const getGeoLocationsResponse = await jmisService.getGeoLocation();
      dnsRoutingStore.setGeoLocationListResponse(getGeoLocationsResponse);
    } catch (e) {
      logger.error(messagesService.dns_routing_fetch_geo_locations_failed, e);
      useNotifications().error(
        messagesService.dns_routing_fetch_geo_locations_failed,
      );
    }
  };

  const updateExistingPolicy = async (
    routingPolicyId: string,
    request: CreateOrUpdatePolicyRequest,
  ) => {
    const routingPolicyModel = await jmisService.updateDnsRoutingPolicy(
      routingPolicyId,
      request,
    );
    return routingPolicyModel;
  };

  return {
    fetchGeoLocationsList,
    fetchRoutingPolicies,
    fetchJpdHealthStatus,
    updateExistingPolicy,
  };
};
