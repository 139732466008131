import {
  FetchUsageRequest,
  FetchUsageResponse,
} from "@shared/services/usage-explorer/fetchUsage.ts";
import {
  fillPartitionItems,
  replaceData,
} from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-mock-tools.ts";
import { UnitFamily } from "@shared/utils/myJFrogUtils.ts";
import { NUMBER_OF_DAYS_IN_RANGE } from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-mock-const.ts";

const json = import(
  "../../json/usageExplorer/usageExplorerGetUsageProjects.json"
);

export default async (data?: object) => {
  const response = JSON.parse(JSON.stringify(await json)) as FetchUsageResponse;
  response.nonce = (data as FetchUsageRequest).nonce;
  fillPartitionItems(response, NUMBER_OF_DAYS_IN_RANGE);
  replaceData({
    response,
    dataPerDay: [
      226, 230, 230, 234, 234, 234, 234, 242, 242, 242, 242, 255, 251, 251, 251,
      274, 284, 295, 300, 300, 300, 297, 278, 278, 278, 278, 278, 278, 284, 284,
    ].reverse(),
    repartitionPercentage: [48, 19, 12, 8, 5, 3, 2, 2, 1],
    unitFamily: UnitFamily.SIMPLE_NUMBER,
    computationMode: "peak",
  });
  return response;
};
