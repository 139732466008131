import {
  FetchUsageRequest,
  FetchUsageResponse,
} from "@shared/services/usage-explorer/fetchUsage.ts";
import {
  fillPartitionItems,
  replaceData,
} from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-mock-tools.ts";
import { UnitFamily } from "@shared/utils/myJFrogUtils.ts";
import { NUMBER_OF_DAYS_IN_RANGE } from "@shared/services/tour/mockedApis/usageExplorer/usage-explorer-mock-const.ts";

const json = import(
  "../../json/usageExplorer/usageExplorerGetUsageCuration.json"
);

export default async (data?: object) => {
  const response = JSON.parse(JSON.stringify(await json)) as FetchUsageResponse;
  response.nonce = (data as FetchUsageRequest).nonce;
  fillPartitionItems(response, NUMBER_OF_DAYS_IN_RANGE);
  replaceData({
    response,
    dataPerDay: [
      99, 122, 152, 289, 246, 277, 296, 246, 146, 194, 265, 286, 294, 319, 235,
      147, 242, 251, 198, 197, 273, 268, 182, 233, 313, 314, 450, 342, 273, 185,
    ].reverse(),
    repartitionPercentage: [85, 7, 4, 2, 1, 1],
    unitFamily: UnitFamily.SIMPLE_NUMBER,
    computationMode: "overtime",
  });
  return response;
};
