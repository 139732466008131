import { RestClient } from "@jfrog-ba/myjfrog-common/dist/rest-client-creator";
import { MY_JFROG_APIS } from "./myjfrogApis.ts";
import {
  SESSION_GET_LINKED_SESSION,
  USER_LOGIN,
  USER_LOGOUT,
  USER_URL,
  USER_VALIDATE_EMAIL,
} from "@shared/services/tour/urls/dashboard-urls.ts";
import logger from "@shared/utils/logger.ts";

type RestTour = RestClient;

const getMyJFrogApi = (url: string) => {
  const modifiedUrl = url.replace(/\d+/g, "*");
  return MY_JFROG_APIS[modifiedUrl as keyof typeof MY_JFROG_APIS];
};

const updateUrl = (url: string) => {
  const excludedUrls = [
    USER_LOGIN,
    USER_URL,
    USER_LOGOUT,
    USER_VALIDATE_EMAIL,
    SESSION_GET_LINKED_SESSION,
  ];
  if (excludedUrls.includes(url)) {
    return;
  }

  logger.error("URL needs to be prepared for tour: ", url);
};

export const restTourCreator = (axiosRestClient: RestClient) => {
  return {
    get: async (url: string, config?: object) => {
      const myJFrogApi = getMyJFrogApi(url);
      if (!myJFrogApi) {
        updateUrl(url);
        return await axiosRestClient.get(url, config);
      }
      return myJFrogApi();
    },
    post: async (url: string, data: object, config?: object) => {
      const myJFrogApi = getMyJFrogApi(url);
      if (!myJFrogApi) {
        updateUrl(url);
        return await axiosRestClient.post(url, data, config);
      }
      return myJFrogApi(data);
    },
    put: async (url: string, data: object, config?: object) => {
      const myJFrogApi = getMyJFrogApi(url);
      if (!myJFrogApi) {
        updateUrl(url);
        return await axiosRestClient.put(url, data, config);
      }
      return myJFrogApi(data);
    },
    patch: async (url: string, data: object) => {
      const myJFrogApi = getMyJFrogApi(url);
      if (!myJFrogApi) {
        updateUrl(url);
        return await axiosRestClient.delete(url, data);
      }
      return myJFrogApi(data);
    },
    delete: async (url: string, data: object) => {
      const myJFrogApi = getMyJFrogApi(url);
      if (!myJFrogApi) {
        updateUrl(url);
        return await axiosRestClient.delete(url, data);
      }
      return myJFrogApi(data);
    },
  } as RestTour;
};
