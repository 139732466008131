import { type RouteRecordRaw } from "vue-router";
import { type MjfRouteMeta, RouteNames } from "@shared/types/localTypes/router";
import {
  beforeEnterQuickUpgrade,
  canAccessSubscription,
  checkForMultiSubscription,
  disableBetaIfNeeded,
  redirectNonAccessibleJpd,
  redirectToSecurityView,
  setCurrentSubscription,
} from "@/router/guard/dashboard";

export default {
  path: "/",
  name: RouteNames.Root,
  redirect: { name: RouteNames.AllSubscriptions },
  component: () => import("@/views/layout/LayoutBase.vue"),
  children: [
    {
      path: "subscriptions",
      component: () => import("@/views/layout/BasicRouterView.vue"),
      children: [
        {
          path: "",
          name: RouteNames.AllSubscriptions,
          component: () =>
            import("@/views/allSubscriptions/AllSubscriptions.vue"),
          beforeEnter: checkForMultiSubscription,
        },
        {
          path: ":subscriptionId(\\d+)",
          component: () =>
            import("@/views/layout/SingleSubscriptionRouterView.vue"),
          beforeEnter: [
            canAccessSubscription,
            setCurrentSubscription,
            disableBetaIfNeeded,
          ],
          redirect: { name: RouteNames.Dashboard },
          children: [
            {
              path: "dashboard",
              name: RouteNames.Dashboard,
              component: () => import("@/views/dashboard/Dashboard.vue"),
            },
            {
              path: "usage-explorer",
              name: RouteNames.UsageExplorer,
              component: () =>
                import("@/views/usage-explorer/UsageExplorer.vue"),
            },
            {
              path: "billing",
              name: RouteNames.Billing,
              component: () => import("@/views/billing/Billing.vue"),
            },
            {
              path: "topology",
              name: RouteNames.Topology,
              component: () => import("@/views/topology/Topology.vue"),
            },
            {
              path: "security",
              name: RouteNames.Security,
              component: () => import("@/views/security/Security.vue"),
              redirect: { name: RouteNames.SecurityTopology },
              children: [
                {
                  path: "",
                  name: RouteNames.SecurityTopology,
                  meta: { parentRoute: RouteNames.Security } as MjfRouteMeta,
                  beforeEnter: redirectToSecurityView,
                  component: () =>
                    import("@/views/security/SecurityJpdTable.vue"),
                },
                {
                  path: ":serverName",
                  name: RouteNames.SecurityJpd,
                  meta: { parentRoute: RouteNames.Security } as MjfRouteMeta,
                  component: () =>
                    import("@/views/security/securityJpd/SecurityJpd.vue"),
                  beforeEnter: redirectNonAccessibleJpd,
                },
              ],
            },
            {
              path: "users",
              name: RouteNames.Users,
              component: () => import("@/views/users/ManageUsers.vue"),
            },
            {
              path: "contacts",
              name: RouteNames.SupportContacts,
              component: () =>
                import(
                  "@/views/manageSupportContacts/ManageSupportContacts.vue"
                ),
            },
            {
              path: "settings",
              name: RouteNames.Settings,
              component: () => import("@/views/settings/SettingsWrapper.vue"),
            },
            {
              path: "notification-center",
              name: RouteNames.NotificationCenter,
              component: () =>
                import(
                  "@/views/notification-center/NotificationCenterWrapper.vue"
                ),
            },
            {
              path: "audit-logs",
              name: RouteNames.ActivityLog,
              component: () => import("@/views/activity-log/ActivityLog.vue"),
            },
            {
              path: "jpds",
              name: RouteNames.JPDs,
              component: () => import("@/views/jpds/JPD.vue"),
            },
            {
              path: "licenses",
              name: RouteNames.Licenses,
              component: () => import("@/views/licenses/Licenses.vue"),
            },
            {
              path: "tickets",
              name: RouteNames.SupportTickets,
              component: () =>
                import("@/views/supportTickets/SupportTicketsView.vue"),
            },
            {
              path: "upgrade",
              name: RouteNames.QuickUpgrade,
              meta: { isLandingView: true } as MjfRouteMeta,
              beforeEnter: beforeEnterQuickUpgrade,
              component: () => import("@/views/quick-upgrade/QuickUpgrade.vue"),
            },
            {
              path: "sandbox",
              name: RouteNames.Sandbox,
              component: () => import("@/views/sandbox/SandBox.vue"),
            },
          ],
        },
      ],
    },
  ],
} as RouteRecordRaw;
