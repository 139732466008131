import { useSubscriptionsStore } from "@shared/stores/subscriptions";
import { useAuthStore } from "@shared/stores/auth";
import { rolesService } from "@shared/services/roles";
import { useEngine } from "@shared/use/useEngine.ts";
import { getHeapClient } from "@shared/module-transformers/getHeapClient.ts";
import { HeapMethods } from "@shared/types/global.ts";
import { UserDTO } from "@jfrog-ba/myjfrog-common";
import { LogInfo } from "@shared/types/localTypes/auth.ts";
import { PropertyValue } from "@heap/heap-javascript-bridge-support/dist/heap/types";
import { useThemeStore } from "@shared/stores/theme.ts";
import { useNavStore } from "@shared/stores/navigation.ts";

type HeapService = {
  clearAll: () => void;
  setSubscriptionProperties: () => void;
  setUserIdentityProperties: () => void;
  track: HeapMethods["track"];
  trackLoginSuccess: (user: UserDTO) => void;
  trackLoginFailed: (arg: {
    loginInfo: LogInfo;
    reason: string;
    details?: Record<string, PropertyValue>;
  }) => void;
};

export const heapService: HeapService = {
  clearAll: () => {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    heap.resetIdentity();
    heap.clearEventProperties();
  },
  setSubscriptionProperties(): void {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    const subscriptionStore = useSubscriptionsStore();
    const authStore = useAuthStore();
    const role = rolesService.extractUserRoleForSubscription(
      subscriptionStore.getCurrentSubscriptionNumber,
    );
    heap.clearEventProperties();
    const currentSubscription = subscriptionStore.getCurrentSubscription;
    if (!currentSubscription) {
      return;
    }
    heap.addEventProperties({
      ...commonHeapProps(),
      subscriptionId: currentSubscription.accountNumber,
      subscriptionType: currentSubscription.paymentType,
      subscriptionState: currentSubscription.state,
      isTrialSubscription: currentSubscription.trial,
      hasBusinessSecurity: currentSubscription.hasBusinessSecurity,
      paymentType: currentSubscription.meta.paymentTypeName,
      isSaas: currentSubscription.meta.isSaas || "undefined",
      isSelfHosted: currentSubscription.meta.isSelfHosted || "undefined",
      role: role || "undefined",
      shBomType: currentSubscription.bom?.type || "undefined",
      loginType: authStore.loginInfo?.type || "undefined",
      loginProvider: authStore.loginInfo?.provider || "undefined",
      platformIdentification:
        authStore.getUser?.platformIdentification || "N/A",
    });
  },

  setUserIdentityProperties(): void {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    const authStore = useAuthStore();
    if (!authStore.getUser) {
      return;
    }
    heap.resetIdentity();
    heap.identify(authStore.getUser.heapIdentity);
    const isInternalJfrogUser = authStore.getUser.email
      .toLowerCase()
      .includes("@jfrog.com");

    heap.addUserProperties({
      ...commonHeapProps(),
      isInternalJfrogUser,
      loginType: authStore.loginInfo?.type || "undefined",
      loginProvider: authStore.loginInfo?.provider || "undefined",
    });
  },
  track: (event, properties, timestamp, sourceInfo) => {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }

    const navStore = useNavStore();

    const eventProperties = {
      ...commonHeapProps(),
      ...(properties || {}),
      menu: navStore.getActiveMenu?.name || "unknown",
      viewTabName: navStore.getViewTabName || "unknown",
    };

    heap.track(event, eventProperties, timestamp, sourceInfo);
  },
  trackLoginSuccess: (user: UserDTO) => {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    const authStore = useAuthStore();
    heap.track("LOGIN_SUCCESS", {
      ...commonHeapProps(),
      heapIdentity: user.heapIdentity,
      isInternalJfrogUser: user.email.includes("@jfrog.com"),
      loginType: authStore.loginInfo?.type || "undefined",
      loginProvider: authStore.loginInfo?.provider || "undefined",
    });
  },

  trackLoginFailed: ({ loginInfo, reason, details }) => {
    const heap = getHeapClient();
    if (!heap) {
      return;
    }
    heap.track("LOGIN_FAILED", {
      ...commonHeapProps(),
      loginType: loginInfo.type,
      loginProvider: loginInfo.provider,
      reason,
      ...details,
    });
  },
};

const commonHeapProps = () => ({
  myjfrogEngine: useEngine().engine,
  theme: useThemeStore().activeTheme,
  screenWidth: window.screen.width,
  screenHeight: window.screen.height,
  viewportWidth: window.innerWidth,
  viewportHeight: window.innerHeight,
});
