import {
  type SubmitSslRequest,
  type SubscriptionDTO,
  type UpdateExistingSslRequest,
} from "@jfrog-ba/myjfrog-common";
import { jmisService } from "@shared/services/jmis.ts";
import {
  type CertificatePageFormAndMeta,
  type ManageDomainsPageStateAndMeta,
} from "@shared/types/localtypes.ts";
import { useSubscriptionsStore } from "@shared/stores/subscriptions.ts";
import { useAuthStore } from "@shared/stores/auth.ts";
import isEqual from "lodash/isEqual";
import { useNotifications } from "@shared/module-transformers/useNotifications.ts";
import { messagesService } from "@shared/services/messages.ts";
import useSettingsHelpers from "@shared/use/useSettingsHelpers.ts";
import { useCnameStore } from "@shared/stores/cname.ts";
import { permissions } from "@shared/services/permissions.ts";
import logger from "@shared/utils/logger.ts";

export const cnameService = {
  submitCertificate: async (
    mode: "create" | "edit" | "renew",
    certificateDetails: CertificatePageFormAndMeta,
    manageDomainsState: ManageDomainsPageStateAndMeta,
  ) => {
    const baseRequest: SubmitSslRequest = {
      certificate_name: certificateDetails.certificateName,
      certificate_body: certificateDetails.certificateBody,
      certificate_private_key: certificateDetails.certificatePrivateKey,
      certificate_chain: certificateDetails.certificateChain,
      domains_under_certificate:
        manageDomainsState.domainUnderCertificateSubmitRequest,
      username: useAuthStore().getUser?.email ?? "",
    };

    const request =
      mode === "create"
        ? (baseRequest as SubmitSslRequest)
        : ({
            ...baseRequest,
            certificate_id: certificateDetails.certificateId ?? "",
          } as UpdateExistingSslRequest);

    const accountNumber =
      useSubscriptionsStore().getCurrentSubscriptionNumberInt;

    switch (mode) {
      case "create":
        return await jmisService.submitSslCertificate(request, accountNumber);
      case "edit":
        return await jmisService.editExistingCertificate(
          request as UpdateExistingSslRequest,
          accountNumber,
        );
      case "renew":
        return await jmisService.renewExistingCertificate(
          request as UpdateExistingSslRequest,
          accountNumber,
        );
    }
  },
  getSslList: async (
    currentSubscriptionNumber: SubscriptionDTO["accountNumber"],
  ) => {
    const { isEntitledCname } = useSettingsHelpers();
    const cnameStore = useCnameStore();
    const hasCnameAccessPermissions = permissions.has("Settings.ModuleAccess");
    try {
      if (!isEntitledCname.value || !hasCnameAccessPermissions) {
        return;
      }
      const response = await jmisService.getSslList(currentSubscriptionNumber);
      if (isEqual(response.ssl_certificates, cnameStore.getSslList)) {
        return;
      }
      cnameStore.setSslList(
        response.ssl_certificates.filter(
          (certificate) => certificate.certificate_status !== "deleted",
        ),
      );
    } catch (e) {
      logger.error(messagesService.jmis_get_ssl_list, e);
      useNotifications().error(messagesService.jmis_get_ssl_list);
    }
  },
};
