import { LocalStorageKeys } from "@shared/types/localTypes/storage.ts";
import { onBeforeUnmount } from "vue";
import { useSessionLogic } from "@shared/use/useSessionLogic.ts";

export const useSessionLoginListener = () => {
  const sessionLogic = useSessionLogic();
  const handleChangeInLocalStorageInOtherTab = async (event: StorageEvent) => {
    if (
      event.key === LocalStorageKeys.LAST_SUCCESSFUL_LOGOUT ||
      event.key === LocalStorageKeys.LAST_SUCCESSFUL_LOGIN
    ) {
      if (sessionLogic.canReload()) {
        sessionLogic.lockReload();
        location.reload();
      }
    }
  };

  window.addEventListener("storage", handleChangeInLocalStorageInOtherTab);

  onBeforeUnmount(() =>
    window.removeEventListener("storage", handleChangeInLocalStorageInOtherTab),
  );
};
