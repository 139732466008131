import type { EntitlementDTO, SubscriptionDTO } from "@jfrog-ba/myjfrog-common";
export enum EntitlementName {
  VIRTUAL_MOTHERSHIP_QTY = "VIRTUAL_MOTHERSHIP_QTY", // E+
  VIRTUAL_INSTANCES_QTY = "VIRTUAL_INSTANCES_QTY", // E+
  EDGE = "EDGE", // E+
  VIRTUAL_PURCHASED_DT = "VIRTUAL_PURCHASED_DT", // consumption
  VIRTUAL_PURCHASED_STORAGE = "VIRTUAL_PURCHASED_STORAGE", // consumption
  VIRTUAL_PURCHASED_USAGE = "VIRTUAL_PURCHASED_USAGE", // consumption
  JFRT = "JFRT",
  JFXR = "JFXR",
  PIPELINES = "PIPELINES",
  PROJECTS = "PROJECTS",
  PDN = "PDN",
  SCANS = "SCANS",
  COLD_STORAGE = "COLD_STORAGE",
  COLD_STORAGE_UPLOAD = "COLD_STORAGE_UPLOAD",
  BUILD_MINUTES = "BUILD_MINUTES",
  BUSINESS_SECURITY_ADVANCED_USERS = "BUSINESS_SECURITY_ADVANCED_USERS",
  BUSINESS_SECURITY_ADVANCED = "BUSINESS_SECURITY_ADVANCED",
  CUSTOMER_UX_CODE = "CUSTOMER_UX_CODE",
  CNAME = "CNAME",
  MANUAL_FAILOVER_POLICY = "MANUAL_FAILOVER_POLICY",
  GEOLOCATION_POLICY = "GEOLOCATION_POLICY",
  BUSINESS_SECURITY_CURATION = "BUSINESS_SECURITY_CURATION",
  BUSINESS_SECURITY_CURATION_USERS = "BUSINESS_SECURITY_CURATION_USERS",
  LOG_STREAMER = "LOG_STREAMER",
}
export interface Entitlement extends EntitlementDTO {
  trial: boolean;
  startDate: number;
  endDate: number;
}

export interface CardConfig {
  icon: string;
  title: string;
  valueTransformer: (value: string, hasTrial?: boolean) => string;
  valueTransformerSH: (value: string) => ShValueTransformer;
  isTrial: (subscription: SubscriptionDTO) => boolean;
  expirationDate?: (subscription: SubscriptionDTO) => number | undefined;
  setMeUp?: () => void;
  displayEmptyState?: boolean | undefined;
  displayNA?: boolean | undefined;
  tooltip?: { text: string; link?: string };
}

export interface ShValueTransformer {
  display: boolean;
  value: string;
  units?: string | undefined;
}
export interface BusinessEntitlementConfig {
  entitlementName: EntitlementName;
  displayName: string;
  displayOrder?: number;
  isCustomerFacing: boolean;
  cardConfig?: CardConfig;
}
