import { defineStore } from "pinia";
import { RouteNames } from "@shared/types/localTypes/router";
import { type GenVersion } from "@shared/stores/genVersion.ts";
import { MenuItemIcons } from "@shared/types/enums.ts";

interface NavigationStoreStates {
  activeIndex: null | string;
  viewTabName: null | string;
  menuItems: MenuItem[];
  isMenuOpened: boolean;
}

export enum MenuItemNames {
  Dashboard = "Dashboard",
  UsageExplorer = "Usage Explorer",
  Billing = "Billing",
  Topology = "Topology",
  Security = "Security",
  Users = "Manage Users",
  Settings = "Settings",
  JPD = "JPD",
  Licenses = "Licenses",
  Sandbox = "Sandbox",
  SupportContacts = "Support Contacts",
  NotificationCenter = "Notification Center",
  ActivityLog = "Audit Logs",
  SupportTickets = "Support Tickets",
}

export enum MenuHeaderNames {
  Dashboard = "Dashboard",
  Usage = "Usage",
  Billing = "Billing",
  Topology = "Topology",
  Security = "Security",
  Users = "Manage Users",
  Subscription = "Subscription",
  Settings = "Settings",
  JPD = "JPDs",
  Licenses = "Licenses",
  Upgrade = "Upgrade",
  AllSubscriptions = "All Subscriptions",
  Sandbox = "Sandbox",
  SupportContacts = "Support Contacts",
  SupportTickets = "Support Tickets",
}

export interface MenuItem {
  name: MenuItemNames;
  routeName: RouteNames;
  icon: (version: GenVersion) => string;
  menuIndex: string;
  disabled: boolean;
  hidden: boolean;
}

export const useNavStore = defineStore({
  id: "navigation",
  state: (): NavigationStoreStates => {
    let menuIndex = 0;
    return {
      activeIndex: null,
      viewTabName: null,
      menuItems: [
        {
          name: MenuItemNames.Dashboard,
          routeName: RouteNames.Dashboard,
          icon: (version) =>
            version === "new-gen" ? MenuItemIcons.DASHBOARD : "dashboard",
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.UsageExplorer,
          routeName: RouteNames.UsageExplorer,
          icon: () => MenuItemIcons.USAGE,
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.Billing,
          routeName: RouteNames.Billing,
          icon: (version) =>
            version === "new-gen" ? MenuItemIcons.BILLING : "billing",
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.Topology,
          routeName: RouteNames.Topology,
          icon: (version) =>
            version === "new-gen" ? MenuItemIcons.TOPOLOGY : "deployments",
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.Security,
          routeName: RouteNames.Security,
          icon: (version) =>
            version === "new-gen" ? MenuItemIcons.SECURITY : "security",
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.Users,
          routeName: RouteNames.Users,
          icon: (version) =>
            version === "new-gen" ? MenuItemIcons.USERS : "users",
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.SupportTickets,
          routeName: RouteNames.SupportTickets,
          icon: () => MenuItemIcons.TICKETS,
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.NotificationCenter,
          routeName: RouteNames.NotificationCenter,
          icon: () => MenuItemIcons.NOTIFICATION,
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.ActivityLog,
          routeName: RouteNames.ActivityLog,
          icon: () => "clock-edit",
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.SupportContacts,
          routeName: RouteNames.SupportContacts,
          icon: () => MenuItemIcons.CONTACT,
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.Settings,
          routeName: RouteNames.Settings,
          icon: (version) =>
            version === "new-gen" ? MenuItemIcons.SETTINGS : "settings",
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.JPD,
          routeName: RouteNames.JPDs,
          icon: () => MenuItemIcons.JPD,
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.Licenses,
          routeName: RouteNames.Licenses,
          icon: () => MenuItemIcons.LICENSES,
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
        {
          name: MenuItemNames.Sandbox,
          routeName: RouteNames.Sandbox,
          icon: (version) =>
            version === "new-gen" ? MenuItemIcons.SANDBOX : "settings",
          menuIndex: `${menuIndex++}`,
          disabled: true,
          hidden: true,
        },
      ],
      isMenuOpened: true,
    };
  },
  getters: {
    getActiveIndex: (state) => state.activeIndex,
    getActiveMenu: (state) =>
      state.menuItems.find((menu) => menu.menuIndex === state.activeIndex),
    getMenuItems: (state) => state.menuItems,
    getIsMenuOpened: (state) => state.isMenuOpened,
    getViewTabName: (state) => state.viewTabName,
  },
  actions: {
    setActiveMenu(menuName: MenuItemNames) {
      const relevantMenu = this.menuItems.find((m) => m.name === menuName);
      this.activeIndex = relevantMenu ? relevantMenu.menuIndex : null;
    },
    clearActiveMenu() {
      this.activeIndex = null;
    },
    setViewTabName(viewTabName: string) {
      this.viewTabName = viewTabName;
    },
    clearViewTabName() {
      this.viewTabName = null;
    },
    setMenuItemVisibility(menuName: MenuItemNames, visible: boolean) {
      const relevantMenu = this.menuItems.find(
        (menuItem) => menuItem.name === menuName,
      );
      if (!relevantMenu) {
        return;
      }
      relevantMenu.hidden = !visible;
    },
    setMenuItemDisabled(menuName: MenuItemNames, disabled: boolean) {
      const relevantMenu = this.menuItems.find(
        (menuItem) => menuItem.name === menuName,
      );
      if (!relevantMenu) {
        return;
      }
      relevantMenu.disabled = disabled;
    },
    setMenuOpen(isOpen: boolean) {
      this.isMenuOpened = isOpen;
    },
  },
});
